import CaretDownOutlined from '@ant-design/icons/lib/icons/CaretDownOutlined'
import { Col, Row } from 'antd'
import React, { useState } from 'react'

interface IFaqCard {
  faq: {
    key: string
    title: string | React.ReactElement
    description: string | React.ReactElement
  }
}

function Faq ({ faq }: IFaqCard) {
  const [isCollapsed, setIsCollapsed] = useState(false)

  return (
    <>
      <div onClick={() => setIsCollapsed(prev => !prev)} className={`faq__card ${!isCollapsed && ' faq__card--collapsed'}`}>
        <Row align="middle" justify="space-between" className="faq__header">
          <Col flex="1 1">
            <div className={`faq__card-title ${!isCollapsed && ' faq__card-title--collapsed'}`}>{faq.title}</div>
          </Col>
          <Col flex="0 1">
            <CaretDownOutlined className={!isCollapsed ? 'faq__arrow-down' : 'faq__arrow-up'} />
          </Col>
        </Row>

      </div>
      {isCollapsed && (
        <Row className="faq__body">
          <div className="faq__body-text">
            {faq.description}
          </div>
        </Row>
      )}
    </>
  )
}

export default Faq
