import React from 'react'
import AboutCircles from '../components/AboutCircles/AboutCircles'
import AboutGoSimulation from '../components/AboutGoSimulation/AboutGoSimulation'
import AboutSection from '../components/AboutSection/AboutSection'
import IntroAbout from '../components/IntroAbout/IntroAbout'

function About () {
  return (
    <div id="container">
      <section id="video">
        <IntroAbout />
      </section>
      <section id="about">
        <AboutSection />
      </section>
      <section id="circles">
        <AboutCircles />
      </section>
      <section id="goSimulation">
        <AboutGoSimulation />
      </section>
    </div>
  )
}

export default About
