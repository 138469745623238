import React, { useEffect, useState } from 'react'
import { ProSidebar, Menu, MenuItem, SidebarContent, SidebarFooter } from 'react-pro-sidebar'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import 'react-pro-sidebar/dist/css/styles.css'
import { Col } from 'antd'
import { useHistory } from 'react-router-dom'

function Sidebar ({ isSidebarCollapsed, setIsSidebarCollapsed }: { isSidebarCollapsed: boolean, setIsSidebarCollapsed: Function }) {
  const screens = useBreakpoint()
  const history = useHistory()

  const [selectedMenu, setSelectedMenu] = useState(window.location.pathname)

  const opacity: React.CSSProperties = {
    position: 'fixed',
    background: 'rgba(0, 0, 0, 0.466)',
    height: '100vh',
    width: '100vw',
    zIndex: 14
  }

  useEffect(() => {
    setSelectedMenu(window.location.pathname)
  }, [window.location.pathname])

  return (
    <>
      <div style={!screens.lg && !isSidebarCollapsed ? opacity : undefined} onClick={() => !screens.md && setIsSidebarCollapsed(true)} />

      <ProSidebar className='sidebar' collapsed={isSidebarCollapsed}>
        <SidebarContent>
          <Menu iconShape='round' popperArrow={false}>
            <MenuItem>
              <Col className="mb-4 sidebar__group-item">
                <a className="sidebar__menu-item" onClick={() => history.push('/' + localStorage.getItem('agx_hash'))}>simule agora</a>
              </Col>
              <Col className="mb-4 sidebar__group-item">
                <a className="sidebar__menu-item" onClick={() => history.push('/about' + localStorage.getItem('agx_hash'))}>quem somos</a>
              </Col>

              {!['/about'].includes(selectedMenu) && (
                <>
                  <Col className="mb-4 sidebar__group-item">
                    <a className="sidebar__menu-item" href="#steps">como funciona</a>
                  </Col>

                  <Col className="mb-4 sidebar__group-item">
                    <a className="sidebar__menu-item" href="#faq">perguntas frequentes</a>
                  </Col>
                </>
              )}
            </MenuItem>
          </Menu>
        </SidebarContent>

        <SidebarFooter>
        </SidebarFooter>
      </ProSidebar>
    </>
  )
}

export default Sidebar
