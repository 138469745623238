import { Button, Col, Row } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { whiteClaimfy, jiveLogo } from '../../assets'

function AboutGoSimulation () {
  const history = useHistory()

  return (
    <div className="about-simulation">
      <div className="about-simulation__layout">
        <Row justify="center">
          <Col>
            <img src={whiteClaimfy} alt="Logo Claimfy" className="about-simulation__claimfy" />
            <div className="about-simulation__title-claimfy">
              {"Pessoas físicas e PME's"}
            </div>
          </Col>
          <Col>
            <div className="about-simulation__border" />
          </Col>
          <Col>
            <img src={jiveLogo} alt="Logo Jive" className="about-simulation__jive" />
            <div className="about-simulation__title">
              Empresas e clientes corporativos
            </div>
          </Col>
        </Row>
        <Row justify="center">
          <div className="about-simulation__text">
            Faça a simulação de venda do seu precatório
          </div>
        </Row>
        <Row justify="center">
          <Button
            type="primary"
            className="about-simulation__button"
            onClick={() => { history.push('/' + localStorage.getItem('agx_hash')) }}
          >
            Clique aqui
          </Button>
        </Row>
      </div>
    </div>
  )
}

export default AboutGoSimulation
