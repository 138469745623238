import React from 'react'
import { Col, Row } from 'antd'

function Footer () {
  return (
    <div className="footer">
      <div className="footer__background-top">
        <div className="footer__layout">
          <Row justify="center" className="footer__row-footer">
            <Col className="footer__top-text">
              <Row justify="center">
                ENDEREÇO
              </Row>
              <Row justify="center" className="text-center mt-1">
                Av. Brigadeiro Faria Lima, 1485
                - Torre Norte - 17&deg; andar - CEP: 01452-002
                - São Paulo | SP - Brasil
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <div className="footer__background-bottom">
        <div className="footer__layout">
          Copyright &#32;&copy; 2021 Claimfy. Todos os direitos reservados.
        </div>
      </div>

    </div>
  )
}

export default Footer
