import React from 'react'

function UseTerms () {
  return (
    <article className="mh-col-8-lg mh-col-5-md mh-col-4-sm mh-col-4-xs main-content mh-body__2" role="article">
      {/* <h2>Termo de consentimento de acordo com a lei geral de proteção de dados – LGPD</h2>

      <p>Estamos empenhados em salvaguardar a sua privacidade ao estabelecer esta relação conosco. Este termo tem a finalidade de deixar o mais claro possível a nossa política de coleta e compartilhamento de dados, informando sobre os dados coletados e como os utilizamos.</p>

      <p>Ao utilizar os nossos serviços, você declara o seu <strong>EXPRESSO CONSENTIMENTO</strong> para coletarmos, tratarmos e armazenarmos dados sobre você quando julgarmos necessário a prestação de nossos serviços, tais como:</p>

      <h2>Serviços</h2>

      <p><strong>Informações que você oferece.</strong> Coletamos os dados fornecidos por você no cadastro.</p>

      <p><strong>Comunicação.</strong> Podemos registrar e gravar todos os dados fornecidos em toda comunicação realizada com nossa equipe, seja por correio eletrônico, mensagens, telefone ou qualquer outro meio.</p>

      <h2>FINALIDADES: UTILIZAÇÃO DESTAS INFORMAÇÕES</h2>
      <p>Ao utilizar a plataforma você AUTORIZA EXPRESSAMENTE o uso destas informações para as seguintes FINALIDADES:</p>

      <p><strong>Fornecer, melhorar e desenvolver os Serviços disponíveis.</strong> Usamos os seus dados para melhorar a qualidade dos nosso serviços, através da personalização do serviço prestado.</p>

      <h2>COMPARTILHAMENTO DE DADOS</h2>

      <p>Compartilhamos os seus dados, para fins de viabilizar pagamentos e o processamento de dados, bem como para tornar o produto mais relevante e eficiente a sua finalidade.</p>
      <p>Compartilhamos seus dados com terceiros para cumprir exigências legais, regulatórias ou discais, envolvendo a divulgação de seus dados pessoais a terceiros, a um tribunal, reguladores ou agencias governamentais.</p>

      <h2>ONDE ARMAZENAMOS E PROCESSAMOS SEUS DADOS</h2>

      <p>Os dados coletados são armazenados e processados, para fins de melhorar o desempenho e proteger os dados no caso de uma falha ou outro problema.</p>

      <h2>REPONSABILIDADE DO CONTROLADOR E MEDIDAS DE SEGURANÇA</h2>

      <p>Todos os dados que você nos fornece são tratados unicamente para atingir as finalidades acima listadas.</p>
      <p>Estes dados são armazenados em servidores seguros utilizados de acordo com nossas políticas e padrões de segurança. Todo o tráfego entre nosso servidores ou entre o seu computador e nossos servidores é encriptado através do protocolo seguro SSL ou semelhante.</p>
      <p>Tomamos medidas de boas práticas e certificações existentes no mercado para garantir que os dados que coletamos sejam processados de acordo com segurança onde quer que os dados estejam localizados.</p>

      <h2>QUANTO TEMPO VAMOS ARMAZENAR SUAS INFORMAÇÕES</h2>

      <p>Nós manteremos as informações que coletamos de você até que ocorra a solicitação de exclusão definitiva por sua parte. Neste caso, nós cessaremos imediatamente a utilização dos seus dados para fins comerciais, porém armazenaremos os seus dados enquanto tenhamos obrigações legais, tributarias ou jurídicas a cumprir com tais dados.</p>

      <h2>DADOS DO ENCARREGADO PELOS DADOS</h2>

      <p>A gestão do seus dados é feita por nossa equipe.</p>
      <p>Você também tem o direito de contratar diretamente a autoridade de proteção de dados brasileira.</p>
      <p>Com base na lei n°13.853/2019, que alterou o Art.5°</p>

      <h2>DIREITOS DO TITULAR DOS DADOS</h2>

      <p>O titular dos dados pessoais tem direito a obter do controlador, em relação aos dados do titular por ele tratados, a qualquer momento e mediante requisição:</p>
      <ol type="I" style={{ marginLeft: '2rem' }}>
        <li>Confirmação da existência de tratamento;</li>
        <li>Acesso aos dados;</li>
        <li>Correção de dados incompletos, inexatos ou desatualizados;</li>
        <li>Anonimação, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto nesta Lei;</li>
        <li>Eliminação dos dados pessoais tratados com o consentimento do titular, exceto nas hipóteses previstas no art.16 da LGPD;</li>
        <li>Informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados;</li>
        <li>Informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;</li>
        <li>Revogação do consentimento, nos termos do 5° do art. 8° da LGPD</li>
      </ol>

      <p>Vamos exercer imediatamente as solicitações, nos termos da lei da proteção de dados aplicável.</p>

      <h2>COMO ENTRAR EM CONTATO CONOSCO</h2>

      <p>E-mail: suporte@agxsoftware.com</p> */}

    </article>

  )
}

export default UseTerms
