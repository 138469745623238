import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Form, Input, FormInstance, Row } from 'antd'
import ArrowLeftOutlined from '@ant-design/icons/lib/icons/ArrowLeftOutlined'
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined'
import simulation from '../consumers/simulation'
import { ISimulation } from '../Simulation'
import InputText from '../../InputText/InputText'
import format from '../../../utils/format'
import { validateAllResponse } from '../../../utils/validate'

interface ISimulationConfirmemail {
  simulationData?: ISimulation
  id: string
  onBack?: (value: boolean) => void
  onNext?: (value: boolean) => void
}

function SimulationConfirmEmail ({ simulationData, id, onBack, onNext }: ISimulationConfirmemail) {
  const formRef = useRef<FormInstance>(null)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isEditEmail, setIsEditEmail] = useState(false)
  const [errors, setErrors] = useState<any>({})
  const [phoneNumber, setPhoneNumber] = useState('')

  useEffect(() => {
    formRef.current?.setFieldsValue({ email: simulationData?.integration.email })
  }, [simulationData?.integration.email])

  async function onSubmit (value: any) {
    setErrors({})
    setIsLoading(true)
    try {
      if (value?.cellphone) value.cellphone = format.onlyDigits(value.cellphone)
      if (id) value.id = id
      value.step = '2'

      const response = await simulation.sendSMS(value)
      if (response.data) {
        onNext && onNext(true)
      }
    } catch (err) {
      console.error(err)
      if (err && err?.errors && err.errors?.invalid) {
        validateAllResponse(err.errors.invalid, err.message, setErrors, setIsLoading)
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Form
      layout="vertical"
      onFinish={onSubmit}
      ref={formRef}
      className="h-100"
    >
      <div className="confirm-email__name">
        Olá, {simulationData?.integration.name}!
      </div>

      <div className="confirm-email__body">
        <div className="confirm-email__sub-title">
          Para facilitar nossa comunicação, confira se esse é o seu melhor e-mail:
        </div>
        <Row justify="center">
          <Input.Group compact>
            <Row style={{ whiteSpace: 'nowrap' }} className={'w-100 flex flex-justify-center'}>
              <Form.Item
                noStyle
                name="email"
                className="w-100"
                help={errors.email && (errors.email)}
                validateStatus={errors.email && ('error')}
              >
                <Input placeholder="Digite seu email" disabled={!isEditEmail} className={`confirm-email__edit ${errors?.email && ' confirm-email__input-error'}`} style={{ textAlign: 'center' }} />
              </Form.Item>
              <Button onClick={() => setIsEditEmail(prev => !prev)} className="confirm-email__pencil-edit" type="link"><EditOutlined /></Button>
            </Row>
            {errors?.email && (
              <span className="confirm-email__span-error">O formato do e-mail está incorreto. Favor editar</span>
            )}
          </Input.Group>
        </Row>
        <div className="confirm-email__email-subtitle">Caso deseje trocar o e-mail, clique no ícone ao lado dele para editar.</div>

        <div className="pb-1 confirm-email__sub-title">
          Agora preencha com o seu número de celular:
        </div>

        <Form.Item
          name="cellphone"
          help={errors.cellphone && (errors.cellphone)}
          validateStatus={errors.cellphone && ('error')}
        >
          <InputText
            style={{ fontSize: '1.1rem', minWidth: 200 }}
            name='cellphone'
            placeholder="(        )"
            mask='cellphone'
            className="confirm-email__cellphone-field"
            value={phoneNumber}
            onChange={(event: any) => setPhoneNumber(event.target.value)}
          />
        </Form.Item>
        <div className="confirm-email__email-subtitle confirm-email__bottom">*enviaremos um código para validação via SMS.</div>
      </div>

      <Row justify="space-between" align="middle" className="confirm-email__footer">
        <Col>
          <Button
            type="link"
            className="simulation-card__back-button"
            onClick={() => onBack && onBack(true)}
          >
            <ArrowLeftOutlined className="simulation-card__arrow-left" />
            Voltar
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            className="simulation-card__next-button"
          >
            {'Prosseguir'}
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default SimulationConfirmEmail
