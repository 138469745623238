import React from 'react'
import { Button, Col, Row } from 'antd'
import ArrowLeftOutlined from '@ant-design/icons/lib/icons/ArrowLeftOutlined'
import CheckCircleOutlined from '@ant-design/icons/lib/icons/CheckCircleOutlined'
import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined'
import ReloadOutlined from '@ant-design/icons/lib/icons/ReloadOutlined'

interface ISimulationConfirmNumber {
  response: any
  onBack?: (value: boolean) => void
  onNext?: (value: boolean) => void
  onSendAgain?: (value: boolean) => void
}

function SuccessConfirm ({ response, onBack, onNext, onSendAgain }: ISimulationConfirmNumber) {
  return (
    <>
      <Row justify="center" align="middle" className="success-confirm__body">
        {response ? (
          <>
            <CheckCircleOutlined className="success-confirm__icon" />
            <div className="success-confirm__text">Código validado com sucesso!</div>
          </>
        ) : (
          <>
            <Col>
              <Row align="middle">
                <ExclamationCircleOutlined className="success-confirm__icon success-confirm__icon--fail" />
                <div className="success-confirm__text success-confirm__text--fail">Código incorreto!</div>
              </Row>
              <Row justify="center" align="bottom">
                <Button
                  type="primary"
                  className="success-confirm__reload-button"
                  onClick={() => {
                    onBack && onBack(true)
                    onSendAgain && onSendAgain(true)
                  }}
                >
                  <ReloadOutlined />
                  Tentar novamente
                </Button>
              </Row>
            </Col>
          </>
        )}
      </Row>

      <Row justify="space-between" align="middle" className="confirm-number__footer">
        <Col>
          <Button
            type="link"
            className="simulation-card__back-button"
            onClick={() => onBack && onBack(true)}
          >
            <ArrowLeftOutlined className="simulation-card__arrow-left" />
            Voltar
          </Button>
        </Col>
        {response && (
          <Col>
            <Button
              type="primary"
              className="simulation-card__next-button"
              // disabled={!isButtonActive ? false : true}
              onClick={() => onNext && onNext(true)}
            >
              Avançar
            </Button>
          </Col>
        )}
      </Row>

    </>
  )
}

export default SuccessConfirm
