import React, { useState } from 'react'
import { Button } from 'antd'
import FaqCard from './components/FaqCard'

const questions = [
  {
    key: '1',
    title: '1. Como faço para verificar o valor do meu precatório?',
    description:
      'Para consultar o valor do seu precatório preencha os seus dados no formulário que se encontra no início da página. Você irá receber um SMS com o código de verificação para poder visualizar os valores.'
  },
  {
    key: '2',
    title: '2. Quanto tempo dura o processo de análise?',
    description:
      'O processo de análise deve ser concluído em até 7 dias úteis e você terá acesso a todas as fases do processo através da nossa plataforma.'
  },
  {
    key: '3',
    title: '3. Como entro em contato em caso de dúvidas durante o processo?',
    description:
    <div>Caso tenha alguma dúvida entre em contato através do e-mail <u>meajuda@claimfy.com.br</u>.</div>
  },
  {
    key: '4',
    title: '4. Não recebi o e-mail de confirmação, o que faço?',
    description:
    <div>Verifique se a mensagem não está no Lixo eletrônico ou na pasta de Spam do seu servidor de e-mails. Caso ainda assim não o receba, entre em contato através do e-mail <u>meajuda@claimfy.com.br</u>.</div>
  },
  {
    key: '5',
    title: '5. Quais os créditos que poderei receber proposta?',
    description:
    'Os créditos a serem antecipados serão aqueles decorrentes de execução ou acordos referentes a ações movidas contra a União Federal. Poderão ser decorrentes de ofícios requisitórios inscritos, sejam eles Requisições de Pequeno Valor ou Precatórios, ou ainda não inscritos, e que se afigurem apenas como direito creditório não formalizado.'
  },
  {
    key: '6',
    title: '6. Por que não estou elegível para receber uma proposta?',
    description:
    'O convênio celebrado para antecipação de créditos é realizado por matéria envolvida ou por determinado grupo de ações. Caso seu processo ainda não esteja elegível ao acordo, isso pode se dar por alguns fatores: a) ausência de valores incontroversos; b) processo ainda não autorizado pela entidade para inclusão no convênio; c) ausência de informações suficientes para elaboração de proposta; d) condições pessoais de endividamento que não permitam se verificar a legalidade da cessão do crédito sem esbarrar em questionamentos de credores. No caso de não estar elegível, o filiado poderá aguardar para receber proposta tão logo os fatores sejam atendidos ou suplantados.'
  },
  {
    key: '7',
    title: '7. Preciso de autorização do advogado da causa?',
    description:
    'Não. O direito ao crédito pertence exclusivamente ao cliente o qual pode dele dispor da forma como achar conveniente e oportuno. Contudo, é importante saber se parte do crédito chegou a ser negociado como forma de pagamento de honorários ao advogado, ocasião em que deverá ser considerado na formação do valor a ser antecipado.'
  },
  {
    key: '8',
    title: '8. Se eu optar pela antecipação do crédito judicial, o que eu preciso fazer?',
    description:
    <div>A opção pela antecipação do crédito implica basicamente em cedê-lo mediante o pagamento do preço pelo adquirente, equivalente ao valor do crédito atribuído no processo descontado determinado percentual usualmente denominando deságio.<br/><br/> O deságio representa a remuneração do adquirente cessionário, e é formado dentre outros fatores, pelo risco de crédito do cedente, reversão do julgado ou bloqueio do crédito, alterações ou mudanças legislativas no regramento orçamentário ou de correção do ativo judiciais, disputas de titularidade entre herdeiros, bem como demais despesas operacionais envolvidas na aquisição. <br/><br/>A opção pela venda é realizada mediante a assinatura de contrato de cessão, cuja formalidade pública ou privada é definida dentro dos parâmetros indicados na plataforma de aquisição ou pelos representantes da adquirente. <br/><br/>Os advogados da adquirente em colaboração dos advogados da entidade ficam responsáveis pelos tramites judiciais necessários para informar diretamente ao Juiz da Execução sobre a cessão do precatório, bem como formalizar a homologação do pedido junto ao presidente do respectivo Tribunal, o qual irá revisar os dados do cessionário e de depósito dos valores futuramente pagos ao adquirente do crédito.</div>
  }
]

function Faq () {
  const [totalQuestions, setTotalQuestions] = useState(3)

  return (
    <div>
      <div className="faq__layout">
        <div className="faq__title">perguntas frequentes</div>
        <div className="faq__sub-title">Entenda o processo mais detalhadamente e tire suas dúvidas:</div>
        {questions.map((item, index) =>
          index < totalQuestions && (
              <FaqCard
                key={item.key}
                faq={item}
              />
          )
        )}
        <Button
          type="primary"
          className="faq__button-more"
          disabled={totalQuestions >= questions.length}
          onClick={() => setTotalQuestions(prev => prev + 3)}
        >
          Ver mais perguntas
        </Button>
      </div>
    </div>
  )
}

export default Faq
