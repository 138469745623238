import React, { useEffect, useState } from 'react'
import { Button, Col, notification, Row } from 'antd'
import PinInput from 'react-pin-input'
import ArrowLeftOutlined from '@ant-design/icons/lib/icons/ArrowLeftOutlined'
import simulation from '../consumers/simulation'

interface ISimulationConfirmNumber {
  userName?: string
  id: string
  resendCodeSMS?: boolean
  onBack?: (value: boolean) => void
  onNext?: (value: boolean) => void
  smsResponse: (value: any) => void
}

function SimulationConfirmNumber ({ userName, id, resendCodeSMS = false, onBack, onNext, smsResponse }: ISimulationConfirmNumber) {
  const [code, setCode] = useState<string | undefined>()
  const [resendLoading, setResendLoading] = useState<boolean>()
  const [isLoading, setIsLoading] = useState<boolean>()
  const [buttonLoading, setButtonLoading] = useState<boolean>()

  async function submitCode () {
    setIsLoading(true)

    try {
      const step = '3'
      const response = await simulation.sendSMS({ code, id, step })
      if (response) {
        smsResponse(response)
      }
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
      onNext && onNext(true)
    }
  }

  const openNotification = (type: string) => {
    type === 'success' && (
      notification.success({
        duration: 2,
        message: 'Código reenviado com sucesso'
      })
    )
    type === 'error' && (
      notification.error({
        duration: 2,
        message: 'Falha ao reenviar o código'
      })
    )
  }

  async function resendCode () {
    setResendLoading(true)
    try {
      const response = await simulation.resendCode(id)
      if (response) {
        openNotification('success')
      }
    } catch (err) {
      openNotification('error')
      console.error(err)
    } finally {
      setResendLoading(false)
    }
  }

  useEffect(() => {
    if (resendCodeSMS === true) resendCode()
  }, [resendCodeSMS])

  return (
    <>
      <div className="confirm-number__body">

        <Row justify="center">
          <div className="confirm-number__input-title">
            digite aqui o código recebido
          </div>
        </Row>
        <Row
          justify="center"
          onKeyDown={(e) => {
            if (e.key === 'Enter') submitCode()
          }}
        >
          <PinInput
            length={6}
            initialValue=''
            onChange={(input) => { input.length === 6 ? setButtonLoading(true) : setButtonLoading(false) }}
            onComplete={(codeComplete) => setCode(codeComplete)}
            type="numeric"
            inputMode="number"
          />
        </Row>
        <Row justify="center">
          <div className="confirm-number__send-code">
            Não recebeu o código?
          </div>
        </Row>
        <Row justify="center">
          <Button
            loading={resendLoading}
            onClick={() => resendCode()}
            className="confirm-number__send-code-link"
            type="link"
          >
            Reenviar
          </Button>
        </Row>
      </div>

      <Row justify="space-between" align="middle" className="confirm-number__footer">
        <Col>
          <Button
            type="link"
            className="simulation-card__back-button"
            onClick={() => onBack && onBack(true)}
          >
            <ArrowLeftOutlined className="simulation-card__arrow-left" />
            Voltar
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            loading={isLoading}
            className="simulation-card__next-button"
            disabled={buttonLoading ? false : true}
            onClick={() => submitCode()}
          >
            {'Prosseguir'}
          </Button>
        </Col>
      </Row>

    </>
  )
}

export default SimulationConfirmNumber
