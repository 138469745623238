import React from 'react'
import { Modal, Row } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

interface ISimulationCard {
  children: React.ReactNode
  inStep?: number
  onBack?: (value: boolean) => void
  onNext?: (value: boolean) => void
}

function SimulationCard ({ children, onBack, onNext, inStep = 1 }: ISimulationCard) {
  const screen = useBreakpoint()

  return (
    <div className="simulation-card">
      <Modal
        centered={!screen.md ? true : false}
        maskStyle={{ backdropFilter: 'blur(0.3rem)' }}
        visible
        footer={false}
        closable={false}
        className="simulation-card__modal position-relative"
      >
        <Row justify="space-between">
          <div className={`simulation-card__step-section simulation-card__step-section-3 ${inStep === 3 && ' simulation-card__step-section--actived '}`}><span>3</span></div>
          <div className={`simulation-card__step-section simulation-card__step-section-2 ${inStep === 2 && ' simulation-card__step-section--actived '}`}><span>2</span></div>
          <div className={`simulation-card__step-section simulation-card__step-section-1 ${inStep === 1 && ' simulation-card__step-section--actived '}`}><span>1</span></div>
        </Row>
        <div className="simulation-card__children-body">
          {children}
        </div>
      </Modal>
    </div>
  )
}

export default SimulationCard
