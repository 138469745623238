import { Modal } from 'antd'
import { SetStateAction } from 'react'

export function validateResponse (data: any) {
  if (!data || !Array.isArray(data)) return {}
  const tempErrors: any = {}
  data.forEach((element: any) => {
    tempErrors[element?.field] = element?.message
  })
  return tempErrors
}

export function validateAllResponse<ErrorType> (errors: ErrorType, errorsMessage: string, setErrors: (value: SetStateAction<ErrorType>) => void, setLoading: (value: SetStateAction<boolean>) => void) {
  const tempErrors: ErrorType = validateResponse(errors)
  setErrors(tempErrors)
  setLoading(false)
  Modal.warning({ title: 'Atenção', content: errorsMessage })
}
