import React, { useEffect, useState } from 'react'
import { Button } from 'antd'
import { arrowDown, logoJiveWhite, sindifiscoWhite } from '../../assets/index'
import SimulationForm from './components/SimulationForm'
import SimulationConfirmNumber from './components/SimulationConfirmNumber'
import SimulationCard from './components/SimulationCard'
import SuccessConfirm from './components/SuccessConfirm'
import SimulationPrecatorio from './components/SimulationPrecatorio'
import SimulationConfirmEmail from './components/SimulationConfirmEmail'
import SimulationRedirect from './components/SimulationRedirect'
import { useHistory } from 'react-router-dom'
import SimulationErrorLogin from './components/SimulationErrorLogin'
import SimulationError from './components/SimulationError'

export interface ISimulation {
  integration: {
    cpf?: string
    email?: string
    formType?: string
    name?: string
    terms?: boolean
    loa?: string
    cellphoneVerified?: boolean
    _id?: string
    precatorioId?: string
  }
}

function Simulation () {
  const history = useHistory()

  const [isClickedSimulation, setIsClickedSimulation] = useState<boolean>(false)
  const [resendCode, setResendCode] = useState<boolean>(false)
  const [idSms, setIdSms] = useState<any>('')
  const [smsResponse, setSmsResponse] = useState<any>('')
  const [inStep, setInStep] = useState<number>()
  const [simulation, setSimulation] = useState<ISimulation>()
  const [simulationConfirmNumber, setSimulationConfirmNumber] = useState<boolean>(false)
  const [simulationConfirmEmail, setSimulationConfirmEmail] = useState<boolean>(false)
  const [successConfirm, setSuccessConfirm] = useState<boolean>(false)
  const [simulationPrecatorio, setSimulationPrecatorio] = useState<boolean>(false)
  const [simulationRedirect, setSimulationRedirect] = useState<boolean>(false)
  const [simulationErrorLogin, setSimulationErrorLogin] = useState<boolean>(false)
  const [simulationError, setSimulationError] = useState<boolean>(false)
  const [simulationErrorMessage, setSimulationErrorMessage] = useState<{ primary: string, secondary: string }>()

  useEffect(() => {
    localStorage.setItem('agx_hash', window.location.search)
  }, [])

  return (
    <>
      <div className="simulation">
        <div className="simulation__banner">
          <div className="simulation__title">
            <span> Transparência </span> e <span>segurança</span> para antecipar seu precatório
          </div>
          <div className="simulation__divider" />
          <div className="simulation__sub-title">
            Conheça a<span className="secondary-color"> parceria </span> do Sindifisco com a Claimfy | Jive
          </div>
        </div>

        <div className="simulation__layout">
          <img className="simulation__logos" height="8%" src={logoJiveWhite} alt="Logo do Clamfy Jive" />
          <img className="simulation__sindifisco-image simulation__logos" height="8%" src={sindifiscoWhite} alt="Logo do Sindifisco Nacional" />
          <div className="simulation__text-title get-in-from-left position-relative">
            <div>
              <span> Transparência </span> e <span>segurança</span> para antecipar seu precatório
            </div>

            <div className="simulation__sub-title">
              <div className="simulation__sub-text">
                Conheça a<span> parceria </span> do Sindifisco com a Claimfy | Jive
              </div>
            </div>

            <div className="simulation__banner-background" />

            <Button
              type="default"
              className="simulation__button-more"
              onClick={() => { history.push('/about' + localStorage.getItem('agx_hash')) }}
            >
              Saiba mais
            </Button>

            <div className="simulation__text-go-down">
              Entenda o processo de venda do seu precatório
            </div>
            <img width="6%" src={arrowDown} alt="flechas apontando para baixo" className="floating-arrow" />
          </div>

          <div className="simulation__form-mobile">
            {!isClickedSimulation && (
              <div className="get-in-from-right">
                <SimulationForm
                  onSend={(value) => {
                    setSimulation(value)
                    setIdSms(value._id)
                  }}
                  onChange={(value) => {
                    setSimulationConfirmEmail(value)
                    setInStep(1)
                    setIsClickedSimulation(value)
                  }}
                  onError={() => {
                    setIsClickedSimulation(true)
                    setSimulationErrorLogin(true)
                  }}
                />
                <div className="simulation__proposal">
                  <div>Já recebeu a sua proposta?</div>
                  <div><a onClick={() => {
                    if (process.env.REACT_APP_URL_INDIKY) {
                      window.open(process.env.REACT_APP_URL_INDIKY)
                    }
                  }}
                  >
                    Clique aqui</a> para verificar o acompanhamento</div>
                </div>
              </div>
            )}

            {isClickedSimulation && (
              <SimulationCard
                inStep={inStep}
              >
                {simulationErrorLogin && (
                  <SimulationErrorLogin
                    onBack={(value) => {
                      if (value) {
                        setIsClickedSimulation(false)
                        setSimulationErrorLogin(false)
                      }
                    }}
                  />
                )}

                {simulationError && (
                  <SimulationError
                    alertText={simulationErrorMessage?.primary}
                    alertExplain={simulationErrorMessage?.secondary}
                    onBack={() => {
                      setSimulationError(false)
                      setSimulationPrecatorio(true)
                    }}
                  />
                )}

                {simulationConfirmEmail && (
                  <SimulationConfirmEmail
                    onBack={(value) => {
                      if (value) {
                        setIsClickedSimulation(false)
                      }
                      setSimulationConfirmEmail(false)
                    }}
                    onNext={(value) => {
                      if (value) {
                        setSimulationConfirmEmail(false)
                      }
                      setSimulationConfirmNumber(value)
                    }}
                    id={idSms}
                    simulationData={simulation}
                  />
                )}
                {simulationConfirmNumber && (
                  <SimulationConfirmNumber
                    onBack={(value) => {
                      if (value) {
                        setSimulationConfirmEmail(value)
                      }
                      setSimulationConfirmNumber(false)
                      setInStep(1)
                    }}
                    onNext={(value) => {
                      if (value) {
                        setSimulationConfirmNumber(false)
                      }
                      setSuccessConfirm(value)
                    }}
                    smsResponse={(value) => {
                      setSmsResponse(value)
                      setSimulation(value.data.simulation)
                    }}
                    resendCodeSMS={resendCode}
                    id={idSms}
                    userName={simulation?.integration.name}
                  />
                )}

                {successConfirm && (
                  <SuccessConfirm
                    onBack={(value) => {
                      if (value) {
                        setSimulationConfirmNumber(value)
                      }
                      setSuccessConfirm(false)
                    }}
                    onNext={(value) => {
                      if (value) {
                        setSuccessConfirm(false)
                      }
                      setSimulationPrecatorio(value)
                      setInStep(2)
                    }}
                    onSendAgain={(value) => {
                      if (value) {
                        setResendCode(true)
                      }
                    }}
                    response={smsResponse}
                  />
                )}

                {simulationPrecatorio && (
                  <SimulationPrecatorio
                    id={idSms}
                    integration={simulation}
                    onBack={(value) => {
                      if (value) {
                        setSuccessConfirm(value)
                      }
                      setSimulationPrecatorio(false)
                    }}
                    onNext={(value) => {
                      if (value) {
                        setSimulationPrecatorio(false)
                      }
                      setSimulationRedirect(value)
                      setInStep(3)
                    }}
                    onError={(show, message) => {
                      setSimulationError(show)
                      setSimulationPrecatorio(false)
                      setSimulationErrorMessage(message)
                    }}
                    userName={simulation?.integration.name}
                  />
                )}

                {simulationRedirect && (
                  <SimulationRedirect
                    simulationData={simulation}
                  />
                )}

              </SimulationCard>
            )}
          </div>
        </div>
      </div>

      <div className="simulation__mobile-background">
        {!isClickedSimulation && (
          <>
            <SimulationForm
              onSend={(value) => {
                setSimulation(value)
                setIdSms(value._id)
              }}
              onChange={(value) => {
                setSimulationConfirmEmail(value)
                setInStep(1)
                setIsClickedSimulation(value)
              }}
              onError={() => {
                setIsClickedSimulation(true)
                setSimulationErrorLogin(false)
              }}
            />
            <div className="simulation__go-down-mobile">
              <div className="simulation__text-go-down">
                veja como é fácil!
              </div>
              <img width="8%" src={arrowDown} alt="flechas apontando para baixo" className="floating-arrow" />
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default Simulation
