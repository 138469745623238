import React from 'react'
import { Input as AntInput } from 'antd'
import masks from '../../utils/masks'

function InputText ({ mask, value = '', error, loading = false, maxLength, ...rest }: any) {
  return (
      <AntInput
      onChange={(e) => masks(e.target.value, mask)}
      value={masks(value as string, mask, true)}
      {...rest}
    />
  )
}

export default InputText
