import React, { MouseEvent } from 'react'
import { Modal } from 'antd'

interface IModalTerms {
  visible: boolean
  children: React.ReactChild | React.ReactChildren
  title: string
  onOk?: (e:MouseEvent<HTMLElement>) => void
  onCancel: (e:MouseEvent<HTMLElement>) => void
}

function ModalTerms ({ visible, onCancel, onOk, children, title }:IModalTerms) {
  return (
    <Modal
      className="terms-modal"
      title={title}
      closable
      visible={visible}
      footer={null}
      onCancel={onCancel}
      centered
    >
    {children}
  </Modal>
  )
}

export default ModalTerms
