import React, { useEffect, useState } from 'react'
import { Row } from 'antd'
import CheckCircleOutlined from '@ant-design/icons/lib/icons/CheckCircleOutlined'
import { ISimulation } from '../Simulation'

function SimulationRedirect ({ simulationData }: { simulationData: ISimulation | undefined }) {
  const [countdown, setCountdown] = useState<number>(20)

  function countdownRedirect () {
    if (process.env.REACT_APP_URL_INDIKY) {
      window.open(process.env.REACT_APP_URL_INDIKY + '/?login=' + simulationData?.integration.cpf, '_self')
    }
  }

  useEffect(() => {
    if (countdown > 0) {
      setTimeout(() => setCountdown(countdown - 1), 1000)
    } else {
      setCountdown(0)
    }
  })

  useEffect(() => {
    setTimeout(() => {
      countdownRedirect()
    }, 20000)
  }, [])

  return (
    <div className="simulation-redirect__item">
      <Row justify="center" align="middle" className="simulation-redirect__body">
        <CheckCircleOutlined className="simulation-redirect__icon" />
        <div className="simulation-redirect__text">Código de venda iniciado com sucesso!</div>
      </Row>

      <div className="simulation-redirect__text-body ">Agora você será redirecionado automaticamente para nossa <b>plataforma de acompanhamento.</b></div>

      <div className="simulation-redirect__text-body simulation-redirect__text-body-limit">Através dela, com <b>transparência</b> e <b>tecnologia</b> descomplicamos as etapas da venda do seu precatório. Envie
        os documentos, acompanhe a nossa análise e assine o contrato de cessão tudo online, através do seu login e senha.</div>

      <div className="simulation-redirect__text-body">Aguarde {countdown}s ou <a onClick={() => countdownRedirect()} className="simulation-redirect__link">clique aqui</a> e acesse agora.</div>
    </div>
  )
}

export default SimulationRedirect
