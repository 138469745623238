import React, { useState } from 'react'
import { Button, Card, Checkbox, Form, Input } from 'antd'
import InputText from '../../InputText/InputText'
import simulation from '../consumers/simulation'
import { useHistory } from 'react-router-dom'
import querystring from 'query-string'
import format from '../../../utils/format'
import { validateAllResponse } from '../../../utils/validate'
import ModalTerms from '../../Terms/ModalTerms'
import LGPDText from '../../Terms/LGPDText'
import UseTerms from '../../Terms/UseTerms'
import UserOutlined from '@ant-design/icons/lib/icons/UserOutlined'
import LockOutlined from '@ant-design/icons/lib/icons/LockOutlined'
import { sindifisco } from '../../../assets'

interface ISimulationForm {
  onChange: (value: boolean) => void
  onSend: (value: any) => void
  onError: (value: boolean) => void
}

function SimulationForm ({ onChange, onSend, onError }: ISimulationForm) {
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [typeTerm, setTypeTerm] = useState('')
  const [errors, setErrors] = useState<any>({})

  const hash = querystring.parse(history.location.search)

  async function onSubmit (value: any) {
    setErrors({})
    setIsLoading(true)
    try {
      value.agx_hash = hash.agx_hash
      if (value?.cpf) value.cpf = format.onlyDigits(value.cpf)

      const response = await simulation.login(value)
      if (response.data) {
        onChange(true)
        onSend(response.data.simulation)
      }
    } catch (err) {
      console.error(err)
      if (err?.code === 418) {
        onError(true)
      }
      if (err && err?.errors && err.errors?.invalid) {
        validateAllResponse(err.errors?.invalid, err.message, setErrors, setIsLoading)
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <Card className="simulation-form">
        <div className="simulation-form__title">simulador de venda</div>
        <Form
          className="simulation-form__form"
          layout="vertical"
          onFinish={onSubmit}
        >
        <div className="simulation-form__sub-title">Utilize o mesmo login e senha do <b>site do Sindifisco</b></div>
          <Form.Item
            name="cpf"
            help={errors.cpf && (errors.cpf)}
            validateStatus={errors.cpf && ('error')}

          >
            <InputText
              name='cpf'
              placeholder="CPF"
              mask='cpf'
              prefix={<UserOutlined />}
            />
          </Form.Item>

          <Form.Item
            className="simulation-form__input-custom"
            name="password"
            help={errors.password && (errors.password)}
            validateStatus={errors.password && ('error')}

          >
            <Input.Password
              placeholder="Senha"
              onChange={() => { }}
              type='password'
              prefix={<LockOutlined />}
            />
          </Form.Item>

          <Form.Item
            name="terms"
            valuePropName="checked"
            help={errors.terms && (errors.terms)}
            validateStatus={errors.terms && ('error')}

          >
            <div className="simulation-form__terms">
              <Checkbox className="simulation-form__checkbox">Aceito os</Checkbox>
              <a onClick={() => { setModalVisible(true); setTypeTerm('use') }}>Termos de Uso</a>
              <span className="simulation-form__primary-color"> e </span>
              <a onClick={() => { setModalVisible(true); setTypeTerm('lgpd') }}>LGPD</a>
            </div>
          </Form.Item>

          <Form.Item>
            <Button
              loading={isLoading}
              htmlType='submit'
              type='primary'
              className="simulation-form__button"
            >
              Login
            </Button>
          </Form.Item>

          <img className="simulation-form__sindifisco-image" src={sindifisco} alt="Logo do Sindifisco Nacional" />

        </Form>
      </Card>

      <ModalTerms
        title={typeTerm === 'lgpd' ? 'Termos LGPD' : 'Termos de Uso'}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
      >
        <div>
          {typeTerm === 'lgpd' && (
            <LGPDText />
          )}
          {typeTerm === 'use' && (
            <UseTerms />
          )}
        </div>
      </ModalTerms>
    </div>
  )
}

export default SimulationForm
