import React from 'react'
import SimulationLayout from './layouts/SimulationLayout'
import Routes from './routes'
import './styles/main.less'

function App () {
  return (
    <Routes />
  )
}

export default App
