const cpfMask = (value: string | undefined) => {
  if (!value) return ''

  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

const cnpjMask = (value: string | undefined) => {
  if (!value) return ''

  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

const cnhMask = (value: string | undefined) => {
  if (!value) return ''

  return value
    .replace(/[^0-9]/gi, '')
    .replace(/([0-9]{11})\d+?$/gi, '$1')
}

const phoneMask = (value: string | undefined) => {
  if (!value) return ''

  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1')
}

const cellphoneMask = (value: string | undefined) => {
  if (!value) return ''

  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1')
}

const rgMask = (value: string | undefined) => {
  if (!value) return ''

  return value
    .replace(/[^a-z\d]/gi, '')
    .replace(/([a-z\d]{2})([a-z\d])/i, '$1.$2')
    .replace(/([a-z\d]{3})([a-z\d])/i, '$1.$2')
    .replace(/([a-z\d]{3})([a-z\d])/i, '$1-$2')
    .replace(/(-[a-z\d]{4})[a-z\d]+?$/i, '$4')
    .toUpperCase()
}

const dateMask = (value: string | undefined) => {
  if (!value) return ''

  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\/\d{4})\d+?$/, '$1')
}

const cepMask = (value: string | undefined) => {
  if (!value) return ''

  return value
    .replace(/\D/g, '')
    .replace(/^(\d{5})(\d)/i, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1')
}

const bankAgencyMask = (value: string | undefined) => {
  if (!value) return ''

  return value
    .replace(/\D/g, '')
}

const bankAccountMask = (value: string | undefined) => {
  if (!value) return ''

  return value
    .replace(/[^0-9x]/gi, '')
    .replace(/^([0-9]{5,13})([0-9x])/gi, '$1-$2')
    .replace(/(-[0-9x]{1})[0-9x]+?$/gi, '$1')
}

const hexMask = (value: string | undefined) => {
  if (!value) return ''

  return value
  // .replace(/[^]/, '')
}

const slugMask = (value: string | undefined) => {
  if (!value) return ''

  return value.replace(/[^a-z\-0-9_]/g, '')
}

const domainMask = (value: string | undefined) => {
  if (!value) return ''

  return value
    .replace(/[^a-z.]/gi, '')
    .replace(/[.][.]/, '.')
}

function masks (value: string, mask?: string, raw?: boolean): string {
  if (!mask) return value
  switch (mask.toLocaleLowerCase()) {
    case 'cpf':
      return cpfMask(value)
    case 'cnpj':
      return cnpjMask(value)
    case 'cnh':
      return cnhMask(value)
    case 'phone':
      return phoneMask(value)
    case 'cellphone':
      return cellphoneMask(value)
    case 'rg':
      return rgMask(value)
    case 'date':
      return dateMask(value)
    case 'bankagency':
      return bankAgencyMask(value)
    case 'bankaccount':
      return bankAccountMask(value)
    case 'cep':
      return cepMask(value)
    case 'hex':
      return hexMask(value)
    case 'slug':
      return slugMask(value)
    case 'domain':
      return domainMask(value)
    default:
      return value
  }
}

export default masks
