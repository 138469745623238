import React from 'react'
import { Col, Row } from 'antd'
import { imageAbout } from '../../assets'

function AboutSection () {
  return (
    <div className="about-section">
      <div className="about-section__layout">
        <div className="about-section__text-title">
          <div className="about-section__about-title">Conheça um pouco mais sobre a Claimfy | Jive, parceira do Sindifisco na venda de precatórios.</div>
          <Row justify="space-between" className="about-section__row">
            <Col flex="0 1 10vw">
              <img src={imageAbout} alt="Prédios" className="about-section__image" />
            </Col>
            <Col flex="0 1 45vw" className="about-section__title">
              A Jive Investments é uma plataforma integrada de gestão de ativos alternativos com
              foco na originação, aquisição e recuperação de créditos inadimplidos (“NPL”), imóveis em situações
              complexas, ativos judiciais e outros ativos <i>distressed</i>.
              <br />
              <br />
              Pioneira no investimento em ativos estressados no
              Brasil e maior player independente do mercado, a Jive investe continuamente em inovação e tecnologia de ponta
              para oferecer a melhor solução em recuperação de créditos, precatórios, imóveis e outros ativos alternativos
              para bancos, empresas e demais credores.
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default AboutSection
