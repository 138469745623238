import React from 'react'
import { logoJiveWhite } from '../../assets'

function Banner () {
  return (
    <div className="banner">
      <div className="banner__floating">
        <div className="banner__floating-title">
          <div className="center">
            segurança e
            <br />
          </div>
          <div>transparência</div>
        </div>
        <img src={logoJiveWhite} alt="Logo Jive Claimfy" className="banner__image" />
      </div>
      <div className="banner__layout">
        <div className="banner__title">segurança e <span className="banner__title--white"> transparência</span></div>
        <img src={logoJiveWhite} alt="Logo Jive Claimfy" className="banner__image" />
      </div>
    </div>
  )
}

export default Banner
