import React from 'react'
import { Button, Col, Row } from 'antd'
import ArrowLeftOutlined from '@ant-design/icons/lib/icons/ArrowLeftOutlined'
import LoginOutlined from '@ant-design/icons/lib/icons/LoginOutlined'

interface ISimulationConfirmNumber {
  onBack?: (value: boolean) => void
}

function SimulationConfirmNumber ({ onBack }: ISimulationConfirmNumber) {
  return (
    <>
      <div className="error-login__body">

        <Row justify="center">
          <div className="error-login__input-title">
            Dados divergentes!
          </div>
        </Row>
        <Row justify="center">
          <div className="error-login__text">
            Seus dados de login e senha estão divergente com o do site do Sindifisco.
          </div>
          <div className="error-login__sub-text">
            Clique em voltar para tentar novamente ou atualize os seus dados diretamente pelo site do Sindifisco na área de login.
          </div>
        </Row>
      </div>

      <Row justify="center" align="middle" className="error-login__footer">
        <Col>
          <Button
            type="link"
            className="simulation-card__back-button"
            onClick={() => onBack && onBack(true)}
          >
            <ArrowLeftOutlined className="simulation-card__arrow-left" />
            Voltar
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            className="error-login__next-button"
            onClick={() => window.open('https://www.sindifisconacional.org.br/', '_blank')}
          >
            <LoginOutlined />
            Ir para o site do Sindifisco
          </Button>
        </Col>
      </Row>

    </>
  )
}

export default SimulationConfirmNumber
