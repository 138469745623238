/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { Button, Col, Radio, Row, Spin } from 'antd'
import ArrowLeftOutlined from '@ant-design/icons/lib/icons/ArrowLeftOutlined'
import querystring from 'query-string'
import simulation from '../consumers/simulation'
import { useHistory } from 'react-router-dom'
import format from '../../../utils/format'
import { ISimulation } from '../Simulation'
import SimulationError from './SimulationError'
import { LoadingOutlined } from '@ant-design/icons'

interface ISimulationConfirmNumber {
  userName?: string
  id: string
  integration: ISimulation | undefined
  onNext?: (value: boolean) => void
  onBack?: (value: boolean) => void
  onError?: (show: boolean, message: {primary: string, secondary: string}) => void
}

interface IPrecatorio {
  name?: string
  numero_processo: string
  precatorio: string
  proposta: string
  months: string
  _id?: string
  loa?: string
}

function SimulationPrecatorio ({ userName, integration, id, onBack, onNext, onError }: ISimulationConfirmNumber) {
  const history = useHistory()

  const [checked, setChecked] = useState<number>(0)
  const [precatorios, setPrecatorios] = useState<IPrecatorio[]>()
  const [loading, setLoading] = useState<boolean>()

  const hash = querystring.parse(history.location.search)

  const antIcon = <LoadingOutlined style={{ fontSize: '5rem' }} spin />

  async function sellSubmit () {
    try {
      if (precatorios && Number(precatorios[checked].precatorio) === 0 && precatorios[checked].proposta === 'Inviável') {
        onError && onError(true, {
          primary: 'Faltam informações para realizar a simulação',
          secondary: 'Não conseguimos obter todas as informações necessárias para realizar a simulação.'
        })
        return
      }
      if (precatorios && Number(precatorios[checked].loa) === 2021) {
        onError && onError(true, {
          primary: 'Precatório consta como pago',
          secondary: 'Seu precatório consta como pago na nossa base de dados.'
        })
        return
      }
      if (integration) integration.integration.precatorioId = precatorios && precatorios[checked]._id

      const data = {
        integration: integration?.integration,
        agx_hash: hash.agx_hash
      }

      const response = await simulation.sell(data)
      if (response) {
        onNext && onNext(true)
      }
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    (async function getPrecatorios () {
      setLoading(true)
      try {
        const response = await simulation.getPrecatorio(id)
        if (response) setPrecatorios(response.precatorios)
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  return (
    <>
      {precatorios && precatorios.length > 0 ? (
        <>
          <div className="simulation-precatorio__name">
            {userName}, esses são os precatórios disponíveis para venda.
          </div>
          <div className="simulation-precatorio__sub-title">
            Selecione qual deseja iniciar o processo:
          </div>

          <div className="simulation-precatorio__body">
            <div className="simulation-precatorio__overflow">
              {precatorios && precatorios.map((card, index) => (
                <div className="simulation-precatorio__card" style={Number(card.precatorio) > 0 && card.proposta !== 'Inviável' ? {} : { cursor: 'not-allowed' }} key={index} onClick={() => {
                  if (Number(card.precatorio) > 0 && card.proposta !== 'Inviável') {
                    setChecked(index)
                  } else {
                    onError && onError(true, {
                      primary: 'Faltam informações para realizar a simulação',
                      secondary: 'Não conseguimos obter todas as informações necessárias para realizar a simulação.'
                    })
                  }
                }}>
                  <div className={`simulation-precatorio__header-card ${index !== checked && 'simulation-precatorio__header-card--disabled'}`}>
                    <Row align="middle">
                      <Radio className="simulation-precatorio__header-checkbox" checked={index === checked ? true : false}></Radio>
                      <div className={`simulation-precatorio__header-title ${index !== checked && 'simulation-precatorio__header-title--disabled'}`}>{card.numero_processo}</div>
                    </Row>
                  </div>
                  <div className={`simulation-precatorio__body-card ${index !== checked && 'simulation-precatorio__body-card--disabled'}`}>
                    <Row justify="space-between" className="simulation-precatorio__body-text">
                      <Col flex="1 1 50vw">
                        <Row>
                          <Col flex="1 1">
                            <div className={`simulation-precatorio__body-card-info ${index !== checked && 'simulation-precatorio__body-card-info--disabled'}`}>Valor do precatório: {format.showMoney(card.precatorio)}</div>
                          </Col>
                        </Row>
                        <Row>
                          <div className={`simulation-precatorio__body-card-value ${index !== checked && 'simulation-precatorio__body-card-value--disabled'}`}>
                            Proposta: {card.proposta !== 'Inviável' ? format.showMoney(card.proposta) : card.proposta}
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <Row justify="space-between" align="middle" className="simulation-precatorio__footer">
            <Col>
              <Button
                type="link"
                className="simulation-card__back-button"
                onClick={() => onBack && onBack(true)}
              >
                <ArrowLeftOutlined className="simulation-card__arrow-left" />
                Voltar
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                className="simulation-card__next-button"
                onClick={() => sellSubmit()}
              >
                Quero vender!
              </Button>
            </Col>
          </Row>

          <div className="simulation-precatorio__observation">
            *Os valores apresentados aqui não vinculam a proposta finalizada. A viabilidade e os valores exatos da antecipação do crédito serão apurados após a análise dos documentos pelos nossos especialistas.
          </div>
        </>
      ) : (
        <>
          {loading ? (
            <Row justify='center' align='middle' className="simulation-precatorio__loading-icon">
              <Spin indicator={antIcon} />
            </Row>
          ) : (
            <SimulationError alertText='Precatório não encontrado' alertExplain='Não encontramos o seu precatório na nossa base de dados.' onBack={onBack} />
          )}
        </>
      )}

    </>
  )
}

export default SimulationPrecatorio
