import React from 'react'
import { Col, Row } from 'antd'
import { jiveAbout } from '../../assets'

function IntroAbout () {
  return (
    <>
      <div className="intro-about">
        <div className="intro-about__layout">
          <div className="intro-about__text-title">
            <Row justify="space-between">
              <Col flex="0 0 50vw" className="intro-about__title">
                <span>faça negócios</span> com quem mais entende do assunto
              </Col>
              <Col className="intro-about__logo">
                <img src={jiveAbout} alt="Logo Jive Claimfy" className="intro-about__image" />
              </Col>
            </Row>
            <div className="intro-about__divider" />
          </div>
        </div>
      </div>
      <iframe className="intro-about__video" src="https://www.youtube.com/embed/O6F70sQWEFQ?wmode=transparent" />
    </>
  )
}

export default IntroAbout
