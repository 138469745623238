import React, { useEffect, useState } from 'react'
import { Layout, Row, Image, Col, Button } from 'antd'
import { MenuOutlined } from '@ant-design/icons'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { sindifisco } from '../../assets'
import { NavLink, useHistory } from 'react-router-dom'

const { Header } = Layout

function Navbar ({ isSidebarCollapsed, setIsSidebarCollapsed, logo }: { isSidebarCollapsed: boolean, setIsSidebarCollapsed: Function, logo: any }) {
  const screen = useBreakpoint()
  const history = useHistory()

  const [isTop, setIsTop] = useState(true)
  const [selectedMenu, setSelectedMenu] = useState(history.location.pathname)

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY < 60) {
        setIsTop(true)
      } else setIsTop(false)
    })
  })

  useEffect(() => {
    return history.listen((location) => {
      setSelectedMenu(location.pathname)
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    })
  }, [history])

  return (
    <Header className="navbar" style={isTop && !['/about'].includes(selectedMenu) ? { zIndex: 0 } : !screen.md && !['/about'].includes(selectedMenu) ? { zIndex: 15, position: 'fixed', backgroundColor: '#001e38EE' } : { backgroundColor: 'white', position: 'fixed' }}>
      <Row align="middle" justify="space-between" className="navbar__items">
        <MenuOutlined
          style={!isTop && !['/about'].includes(selectedMenu) ? { color: 'white', transition: '100ms' } : {}}
          className={`navbar__toggle-menu ${isSidebarCollapsed ? '' : 'navbar__toggle-menu--off'}`}
          onClick={() => setIsSidebarCollapsed((prev: boolean) => !prev)}
        />

        {!screen.md && !isTop ? (
          <Button className="navbar__simule-button" onClick={() => { ['/about'].includes(selectedMenu) ? history.push('/' + localStorage.getItem('agx_hash')) : document.body.scrollTop = 0; document.documentElement.scrollTop = 0 }}>
            simule agora!
          </Button>
        ) : (
          <Row>
            <Image
              className='navbar__image pointer'
              src={logo}
              onClick={() => history.push('/' + localStorage.getItem('agx_hash'))}
              preview={false}
            />
            <Image
              className='navbar__image-sindifisco pointer'
              src={sindifisco}
              onClick={() => window.open('https://www.sindifisconacional.org.br/', '_blank')}
              preview={false}
            />
          </Row>
        )}

        {['/about'].includes(selectedMenu) ? (
          <Col className="navbar__menu" flex="0 1 500px">
            <Row align="middle" justify="end" >
              <Col>
                <a className="navbar__menu-item navbar__menu-item-about">quem somos</a>
                <div className="navbar__selected-item" />
              </Col>

              <Col>
                <Button
                  className="navbar__button-about"
                  onClick={() => history.push('/' + localStorage.getItem('agx_hash'))}
                >
                  Fazer uma simulação de venda
                </Button>
              </Col>
            </Row>
          </Col>
        ) : (
          <Col className="navbar__menu" flex="0 0 500px">
            <Row align="middle" justify="space-between" >
              <Col>
                <NavLink className="navbar__menu-item" to={'/about' + localStorage.getItem('agx_hash')}>quem somos</NavLink>
              </Col>

              <Col>
                <a className="navbar__menu-item" href="#steps">como funciona</a>
              </Col>

              <Col>
                <a className="navbar__menu-item" href="#faq">perguntas frequentes</a>
              </Col>
            </Row>
          </Col>
        )}

      </Row>
    </Header>
  )
}

export default Navbar
