import React from 'react'
import { Button, Col, Row } from 'antd'
import ArrowLeftOutlined from '@ant-design/icons/lib/icons/ArrowLeftOutlined'
import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined'

interface ISimulationError {
  onBack?: (value: boolean) => void
  alertText?: string
  alertExplain?: string
}

function SimulationError ({ onBack, alertText, alertExplain }: ISimulationError) {
  return (
    <>
      <div className="simulation-error__body">
        <Row justify="center">
          <ExclamationCircleOutlined className="simulation-error__icon" />
          <div className="simulation-error__text">{alertText}</div>
        </Row>
        <Row justify="center" className="simulation-error__text-explain">
          <div className="simulation-error__sub-title">{alertExplain}</div>
        </Row>
        <Row justify="center" className="simulation-error__link">
          <div className="simulation-error__sub-title">Para mais informações entre em contato conosco pelo e-mail <a href="mailto:meajuda@claimfy.com.br">meajuda@claimfy.com.br</a>.</div>
        </Row>
      </div>

      <Row justify="space-between" align="middle" className="simulation-error__footer">
        <Col>
          <Button
            type="link"
            className="simulation-card__back-button"
            onClick={() => onBack && onBack(true)}
          >
            <ArrowLeftOutlined className="simulation-card__arrow-left" />
            Voltar
          </Button>
        </Col>
      </Row>

    </>
  )
}

export default SimulationError
