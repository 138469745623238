import React from 'react'
import { Row } from 'antd'
import { pin } from '../../assets/index'

function ProgressSteps () {
  return (
    <div className="progress-steps">
      <div className="progress-steps__layout">
        <div className="progress-steps__title">como funciona o processo de venda do seu precatório?</div>
        <div className="progress-steps__sub-title">Acompanhe as etapas do processo:</div>

        <div className="progress-steps__my-step">
          <img className="progress-steps__my-step-pin floating-arrow" src={pin} alt="pin apontando para o meu passo" />
          <div className="progress-steps__my-step-text">você está aqui</div>
        </div>
        <Row justify="space-between" className="position-relative progress-steps__mobile-version">
          <img className="floating-pin progress-steps__my-step-pin--mobile" src={pin} alt="pin apontando para o meu passo" />
          <div className="progress-steps__card">
            <div className="progress-steps__vertical-progress">
              <div className="progress-steps__legend-step">voce está aqui</div>
              <div className="progress-steps__dot-mobile"></div>
              <Row className="progress-steps__trail-vertical"></Row>
            </div>
            <div className="progress-steps__tails"></div>
            <div className="progress-steps__step">
              <div className="progress-steps__dot">
              </div>

              <div className="progress-steps__step-card">
                consulta
                <div className="progress-steps__step-card-position">1</div>
              </div>
              <div className="progress-steps__step-card-subtitle">
                Consulte os processos disponíveis para venda por meio do nosso site com o número do seu CPF. Nós analisaremos a sua situação e você receberá uma proposta.              </div>
            </div>
          </div>

          <div className="progress-steps__card">
            <div className="progress-steps__vertical-progress">
              <div className="progress-steps__legend-step progress-steps__legend-step--disabled">seu e-mail</div>
              <div className="progress-steps__dot-mobile progress-steps__dot-mobile--disabled"></div>
              <Row className="progress-steps__trail-vertical"></Row>
            </div>
            <div className="progress-steps__step progress-steps__step--disabled">
              <div className="progress-steps__dot progress-steps__dot--disabled"></div>
              <div className="progress-steps__step-card progress-steps__step-card--disabled">
                proposta
                <div className="progress-steps__step-card-position progress-steps__step-card-position--disabled">2</div>
              </div>
              <div className="progress-steps__step-card-subtitle progress-steps__step-card-subtitle--disabled">
                Visualize a proposta e prossiga no processo de venda na nossa plataforma de acompanhamento.
              </div>
            </div>
          </div>

          <div className="progress-steps__card">
            <div className="progress-steps__vertical-progress">
              <div className="progress-steps__legend-step progress-steps__legend-step--disabled">plataforma de gestão</div>
              <div className="progress-steps__dot-mobile progress-steps__dot-mobile--disabled"></div>
              <Row className="progress-steps__trail-vertical"></Row>
            </div>
            <div className="progress-steps__step progress-steps__step--disabled">
              <div className="progress-steps__dot progress-steps__dot--disabled"></div>
              <div className="progress-steps__step-card progress-steps__step-card--disabled">
                análise
                <div className="progress-steps__step-card-position progress-steps__step-card-position--disabled">3</div>
              </div>
              <div className="progress-steps__step-card-subtitle progress-steps__step-card-subtitle--disabled">
                Os documentos serão analisados em até <span className="progress-steps__color-text">7 dias</span> úteis e você consegue acompanhar todo o andamento a qualquer momento, acessando a nossa plataforma.
              </div>
            </div>
          </div>

          <div className="progress-steps__card">
            <div className="progress-steps__vertical-progress">
              <div className="progress-steps__legend-step progress-steps__legend-step--disabled">plataforma de gestão</div>
              <div className="progress-steps__dot-mobile progress-steps__dot-mobile--disabled"></div>
            </div>
            <div className="progress-steps__step progress-steps__step--disabled">
              <div className="progress-steps__dot progress-steps__dot--disabled"></div>
              <div className="progress-steps__step-card progress-steps__step-card--disabled">
                pagamento
                <div className="progress-steps__step-card-position progress-steps__step-card-position--disabled">4</div>
              </div>
              <div className="progress-steps__step-card-subtitle progress-steps__step-card-subtitle--disabled">
                Após a assinatura digital do contrato receba o valor negociado à vista em até <span className="progress-steps__color-text">1 dia útil.</span>
              </div>
            </div>
          </div>
        </Row>
      </div>
    </div>
  )
}

export default ProgressSteps
