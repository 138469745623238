import React, { useState } from 'react'
import Navbar from '../components/Navbar/Navbar'
import { logoNav, logoNavMobile } from '../assets/index'
import Sidebar from '../components/Sidebar/Sidebar'
import Footer from '../components/Footer/Footer'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
interface ISimulationLayout {
  children?: React.ReactNode
}

function SimulationLayout ({ children }: ISimulationLayout) {
  const screen = useBreakpoint()

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true)

  return (
    <div>
      <Sidebar isSidebarCollapsed={isSidebarCollapsed} setIsSidebarCollapsed={setIsSidebarCollapsed} />

      <Navbar isSidebarCollapsed={isSidebarCollapsed} setIsSidebarCollapsed={setIsSidebarCollapsed} logo={screen.md ? logoNav : logoNavMobile} />
      <div className="flex flex-column h-100 w-100 simulation-layout">
        {children}
      </div>

      <Footer />
    </div>
  )
}

export default SimulationLayout
