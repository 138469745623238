const format = {
  onlyDigits: (value: string | undefined) => {
    if (!value && value !== '0') return ''
    return value.replace(/\D/g, '')
  },

  formatBRL: (value: number | undefined): string => {
    if (!value) return 'R$ ' + 0
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  },

  formatBRLMinAndMax: (value: number | undefined): string => {
    if (!value) return 'R$ ' + 0
    return (value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 0 })
  },

  compressedMoney: (value: number | undefined): string => {
    if (!value) return 'R$ ' + 0

    const valueFormatted = new Intl.NumberFormat('pt-BR', { notation: 'compact', style: 'currency', currency: 'BRL' }).format(value)
    return valueFormatted.toUpperCase()
  },

  compressedNumber: (value: number | undefined): string => {
    if (!value) return '' + 0

    const valueFormatted = new Intl.NumberFormat('pt-br', { notation: 'compact', style: 'decimal' }).format(value)
    return valueFormatted.toUpperCase()
  },

  capitalize: (s: string) => {
    return s.charAt(0).toUpperCase() + s.slice(1)
  },

  toBRL: (currency: string) => (value: number) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: currency
    }).format(value)
  },

  separeteValuesByComma: (values: Array<string>) => {
    if (!values) return []
    if (values.length < 1) return values
    return values.reduce(
      (text, value, i, array) =>
        text + (i < array.length - 1 ? ', ' : ' e ') + value
    )
  },

  formatMoneySend: (value: string): number => {
    if (!value) return 0
    let convertValue = String(value)
    const hasComma = convertValue.indexOf(',') !== -1

    if (convertValue.indexOf('R$') !== -1) {
      const temp = convertValue.split(',')
      temp[0] = temp[0].replace(/\D/g, '')
      convertValue = temp.join('.')
    } else { if (hasComma) convertValue = convertValue.replace(/,/g, '.') }

    const numberValue = Number(convertValue)
    return Number(numberValue)
  },

  currencyParser: (val: any) => {
    // for when the input gets clears
    if (typeof val === 'string' && !val.length) {
      val = '0.0'
    }

    // detecting and parsing between comma and dot
    const group = new Intl.NumberFormat('pt-BR').format(1111).replace(/1/g, '')
    const decimal = new Intl.NumberFormat('pt-BR').format(1.1).replace(/1/g, '')
    let reversedVal = val.replace(new RegExp('\\' + group, 'g'), '')
    reversedVal = reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.')

    //  => 1232.21 €
    // removing everything except the digits and dot
    reversedVal = reversedVal.replace(/[^0-9.]/g, '')
    //  => 1232.21
    // appending digits properly

    const digitsAfterDecimalCount = (reversedVal.split('.')[1] || []).length
    const needsDigitsAppended = digitsAfterDecimalCount > 2

    if (needsDigitsAppended) {
      reversedVal = reversedVal * Math.pow(10, digitsAfterDecimalCount - 2)
    }

    return Number.isNaN(reversedVal) ? 0 : reversedVal
  },

  decimalToPercentage: (value: number): number => {
    if (!value) return 0
    return value * 100
  },

  percentageToDecimal: (value: number): number => {
    if (!value) return 0
    return value / 10 / 10
  },

  precisionFloat: (value: string | number): number => {
    if (!value) return 0

    return Number(Number(value).toPrecision(4))
  },

  onlyMoney: (value: string): number | undefined => {
    if (!value) return undefined
    if (typeof value === 'number') return value
    if (value.slice(-3).includes('.')) return Number(value)

    return Number(value.replace(/[^0-9,]/g, '').replace(/,/g, '.'))
  },

  showMoney: (value: number | undefined | string): string => {
    if (typeof value === 'string') value = Number(value.replace(',', '.'))
    if (!value) return 'R$ ' + 0
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  },

  currencyInputFormat: (money: string) => {
    const currencyRegExp = new RegExp(`(\\d{1,${100}})(.)?(\\d{2})`, 'g')
    const pointsRegExp = /(?=(\d{3})+(\D))\B/g
    if (money.length === 5 && money[money.length - 1] !== '0') {
      return money
        .replace(/\D/g, '')
        .replace('0', '')
        .replace(currencyRegExp, (match, p1, p2, p3) => [p1, p3].join(','))
        .replace(pointsRegExp, '.')
    } else {
      return money
        .replace(/\D/g, '')
        .replace(currencyRegExp, (match, p1, p2, p3) => [p1, p3].join(','))
        .replace(pointsRegExp, '.')
    }
  }

  // Format antd Agosto, ano
  // monthCommaYear: (value: Dayjs) => `${months[value.format('MM')]}, ${value.format('YYYY')}`

}

export default format
