import React, { useState } from 'react'
import { Button, Card, Form, FormInstance, Input, Row } from 'antd'
import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined'
import { emailChat } from '../../assets'
import { useForm } from 'antd/lib/form/Form'

interface chat {
  onClose?: (value: boolean) => void
}

function Chat ({ onClose }: chat) {
  const [sentEmail, setSentEmail] = useState(false)
  const [form] = useForm()

  async function onSubmit (values: {name: string, email: string, message: string}) {
    window.location.href = `mailto:meajuda@claimfy.com.br?&subject=Sou ${values.name || 'Anônimo(a)'} e preciso de ajuda&body=Eu sou ${values.name || 'Anônimo(a)'} e meu email ${values.email || 'Não informado'}. ${values.message || ''}`
    setSentEmail(true)
  }

  return (
    <div>
      <Card className="chat" id="chat-card">
        {!sentEmail ? (
          <>
            <Row justify="space-between">
              <div className="chat__title">Tem alguma dúvida?</div>
              <CloseOutlined width="8%" onClick={() => onClose && onClose(false)} className="chat__close-icon" />
            </Row>
            <div className="chat__sub-title">Envie uma mensagem para nós que entraremos em contato com você.</div>
            <Form
              className="chat__form"
              layout="vertical"
              onFinish={onSubmit}
              form={form}
            >
              <Form.Item
                label="Seu nome"
                name="name"
              >
                <Input
                  placeholder="Digite seu nome"
                  onChange={() => { }}
                />
              </Form.Item>

              <Form.Item
                label="Seu e-mail"
                name="email"
              >
                <Input
                  placeholder="Digite seu email"
                />
              </Form.Item>

              <Form.Item
                label="Mensagem"
                name="message"
              >
                <Input.TextArea
                  autoSize={{ minRows: 5, maxRows: 5 }}
                  placeholder="Digite sua mensagem"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  htmlType='submit'
                  type='primary'
                  className="chat__button"
                >
                  Enviar
                </Button>
              </Form.Item>
            </Form>
          </>
        ) : (
          <div className="chat__body-chat-success">
            <img height="8%" src={emailChat} alt="Imagem de uma carta" />
            <div className="chat__message-success">Mensagem enviada com sucesso!</div>
            <div className="chat__text-success">Aguarde que em breve entraremos em contato pelo e-mail que você informou aqui no formulário.</div>
          </div>
        )}
      </Card>
    </div>
  )
}

export default Chat
