import { AxiosResponse } from 'axios'
import api from '../../../services/api'
import { ConnectionFailed, ErrInternetDisconnected } from '../../../consumers/errors'

interface IProposalIntegration {
  // eslint-disable-next-line camelcase
  agx_hash: string | string[] | null
  cellphone: string
  clientName: string
  email: string
  cpf: string
  value: string
  integration: IIntegration
}

interface IIntegration {
  brithdate: string
  cellphone: string
  cellphoneVerified?: boolean
  code: number
  email: string
  cpf: string
  formType?: string
  leadId: string
  name: string
  terms?: boolean
}

const simulation = {
  create: async (data: FormData) => {
    try {
      const response: AxiosResponse = await api.post('/unauth/jive/simulation', data)
      return response?.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },
  getPrecatorio: async (id: string) => {
    try {
      const response: AxiosResponse = await api.get(`/unauth/jive/simulation/${id}/precatorio`)
      return response?.data?.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },
  sendSMS: async (data: {id: string, code?: string, email?: string, cellphone?: string, step?: string}) => {
    try {
      const response: AxiosResponse = await api.patch(`/unauth/jive/simulation/${data.id}`, data)
      return response?.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },
  resendCode: async (id: string) => {
    try {
      const response: AxiosResponse = await api.post(`/unauth/jive/simulation/${id}/resend`)
      return response?.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },
  sell: async (data: any) => {
    try {
      const response: AxiosResponse = await api.post('/api/proposals', data)
      return response
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },
  login: async (data: {agx_hash: string, cpf: string, password: string, terms: boolean}) => {
    try {
      const response: AxiosResponse = await api.post('/unauth/jive/login', data)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  }
}

export default simulation
