import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Home from '../views/Home'
import About from '../views/About'
import SimulationLayout from '../layouts/SimulationLayout'

function Routes () {
  return (
    <Router>
      <SimulationLayout>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
        </Switch>
      </SimulationLayout>
    </Router>
  )
}

export default Routes
