import React, { useState } from 'react'
import Simulation from '../components/Simulation/Simulation'
import ProgressSteps from '../components/ProgressSteps/ProgressSteps'
import Banner from '../components/Banner/Banner'
import Faq from '../components/Faq/Faq'
import { chat, simulation } from '../assets'
import Chat from '../components/Chat/Chat'

function Home () {
  const [showChat, setShowChat] = useState(false)

  return (
    <div id="container">
      <img src={simulation} alt="Simule agora!" className="home-simulation" onClick={() => { document.body.scrollTop = 0; document.documentElement.scrollTop = 0 }} />
      <img src={chat} alt="Chat" id="chat-open" className="home-chat"
        onClick={() => {
          setShowChat(prev => !prev)
        }}
      />
      {showChat && (
        <Chat onClose={(value: boolean) => { setShowChat(value) }} />
      )}
      <section id="simulation">
        <Simulation />
      </section>
      <section id="steps">
        <ProgressSteps />
      </section>
      <section id="banner">
        <Banner />
      </section>
      <section id="faq">
        <Faq />
      </section>
      <section id="chat">
        <Chat />
      </section>
    </div>
  )
}

export default Home
