import { Row } from 'antd'
import React from 'react'

function AboutCircles () {
  return (
    <div className="about-circles">
      <div className="about-circles__layout">
        <Row justify="center">
          <div className="about-circles__circle">
            <div className="about-circles__title">
              + de 1190
            </div>
            <div className="about-circles__sub-title">
              investidores no Brasil e no exterior
            </div>
          </div>

          <div className="about-circles__circle about-circles__circle--light">
            <div className="about-circles__title about-circles__title--light">
              + de <br />R$ 8 bi
            </div>
            <div className="about-circles__sub-title about-circles__sub-title--light">
              em ativos sob gestão (AUM)
            </div>
          </div>

          <div className="about-circles__circle">
            <div className="about-circles__title">
              R$ 22 bi
            </div>
            <div className="about-circles__sub-title">
              em valor de face
            </div>
          </div>

          <div className="about-circles__circle about-circles__circle--light">
            <div className="about-circles__title about-circles__title--light">
              + de 300
            </div>
            <div className="about-circles__sub-title about-circles__sub-title--light">
              colaboradores
            </div>
          </div>
        </Row>
      </div>
    </div>
  )
}

export default AboutCircles
